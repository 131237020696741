import {instance} from './index';

export const stripeApi = {
  createSession (data) {
    return instance.post('stripe/session', data)
  },
  createSetupIntent () {
    return instance.get('stripe/setup-intent')
  },
  payBySavedCard (data) {
    return instance.post('stripe/pay-by-saved-payment-method', data)
  },
  removeCard (id) {
    return instance.delete(`stripe/payment-methods/${id}`)
  },
  getAmountWithFee (amount) {
    return instance.get(`stripe/get-amount-with-fee?amount=${amount}`)
  },
}
