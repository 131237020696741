<template>
  <div style="height: 450px;" class="content-payment-form-base" :class="useFormConstrain ? 'content-payment-form' : ''">
    <vue-scroll :ops="ops">
      <form id="payment-form">
        <div id="payment-element" />
        <p v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
        <div class="button-container">
          <button class="buttonBlack pay-button" @click.prevent="saveCard">
            Save
          </button>
        </div>
      </form>
    </vue-scroll>
    <div v-if="loading" class="spinner-overlay">
      <Spinner />
    </div>
  </div>
</template>

<script>
import {stripeApi} from '@/api/stripe';
import Spinner from '@/components/common/Spinner/Spinner.vue';
import {loadStripe} from '@stripe/stripe-js';

export default {
  name: 'StripeSaveCardForm',
  components: {Spinner},
  props: ['useFormConstrain'],
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: '#bdbaba',
      },
    },
    loading: false,
    errorMessage: '',
    elements: {},
    stripe: {},
  }),
  async mounted () {
    try {
      this.loading = true;
      await this.initialize();
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async initialize () {
      this.stripe = await loadStripe(process.env.VUE_APP_PK_STRIPE)

      if (!this.stripe) {
        this.showMessage('An unexpected error occurred.', 'Failed to load Stripe.')
        return
      }

      const response = await stripeApi.createSetupIntent()

      const elementsOptions = {
        clientSecret: response.data.item.value.clientSecret,
        customerSessionClientSecret: response.data.item.value.customerSessionClientSecret,
        appearance: {
          theme: 'flat',
          labels: 'floating',
        },
      }

      this.elements = this.stripe.elements(elementsOptions)
      const paymentElement = this.elements.create('payment', {
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: true,
        },
        paymentMethodOrder: ['card', 'google_pay', 'apple_pay'],
      })
      paymentElement.mount('#payment-element')
    },

    async saveCard () {
      try {
        this.loading = true

        if (!this.stripe) {
          this.showMessage('An unexpected error occurred.', 'Failed to load Stripe.')
          return
        }

        if (!this.elements) {
          this.showMessage('An unexpected error occurred.', 'Failed to get elements.')
          return
        }

        const { error, paymentIntent } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: this.getReturnUrl(),
          },
          redirect: 'if_required',
        })

        if (error && (error.type === 'card_error' || error.type === 'validation_error')) {
          this.showMessage('There was a problem saving your payment method.')
          return
        }

        await this.initialize();
        this.$emit('save-card-status', paymentIntent?.status)
      } catch (e) {
        this.showMessage('An unexpected error occurred.', e)
      } finally {
        this.loading = false
      }
    },

    getReturnUrl () {
      const { pathname, origin } = window.location

      const pathMap = new Map([
        ['flycalendar', 'dashboard/flycalendar'],
        ['booking', '/map-search'],
        ['manually-request', '/map-search'],
        ['payment', '/dashboard/payment'],
        ['subscriptions', '/dashboard/subscriptions'],
      ])

      for (const [key, path] of pathMap.entries()) {
        if (pathname.includes(key)) {
          return `${origin}${path}`
        }
      }

      return origin
    },

    showMessage (userMessage, logMessage) {
      console.error(logMessage)

      this.errorMessage = userMessage

      setTimeout(function () {
        this.errorMessage = ''
      }, 4000)
    },
  },
}
</script>

<style scoped lang="stylus">
@import '~@/assets/stylus/imports.styl';

.content-payment-form-base {
  width: 400px
  max-height: 500px;
}

.content-payment-form {
  height: 250px;
}

.pay-button {
  margin-top: 30px !important;
  padding: 10px 30px !important;
  border-radius: 20px !important;
}

.error-message {
  padding-top: 10px;
  color: #fe87a1;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .spinner {
    &__item {
      background-color: #f4f4f4;
    }
  }
}

.form .spinner {
  visibility: visible;
  opacity: 100;
}

</style>
